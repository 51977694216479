import './scripts/common';
import {onFind} from "@elements/init-modules-in-scope";

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
import * as lightbox from '@elements/lightbox';
lightbox.init();

import Modal from 'bootstrap/js/dist/modal';
onFind('[data-bs-toggle="modal"]',function (element) {
    new Modal(element);
});

import * as ajaxForm from '@elements/ajax-form';
ajaxForm.init();

import * as cardTeaserSlider from './scripts/card-teaser-slider';
cardTeaserSlider.init();

import * as imgTeaserSlider from './scripts/img-teaser-slider';
imgTeaserSlider.init();

import * as parallax from "./scripts/parallax";
parallax.init();

import * as circleAnimation from './scripts/circle-animation';
circleAnimation.init();

import * as webcamPlayer from './scripts/webcam-player';
webcamPlayer.init();

import * as scaleSliderInput from './scripts/scale-slider-input.js';
scaleSliderInput.init();

import * as panomax from './scripts/panomax.js';
panomax.init();

import * as lazyIframe from '@elements/lazy-iframe';
lazyIframe.init();
