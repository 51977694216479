import {onFind} from "@elements/init-modules-in-scope";
import {removeClass,on,addClass, removeAttribute, setAttribute, findIn} from '@elements/dom-utils';
import {simplePreload} from 'image-preloader';
import axios from "axios";

const selectors = {
    base: '.js-webcam-player',
    datePickerInput: '.js-webcam-player__date',
    datePickerAlt: '.js-webcam-player__date-alt',
    timeSelect: '.js-webcam-player__time',
    playButton: '.js-webcam-player__play',
    img: '.js-webcam-player__img',
    interval: '.js-webcam-player__interval'
};
export function init() {
    onFind(selectors.base, function (baseElement) {

        const container = baseElement;
        const datePickerInput = findIn(selectors.datePickerInput, baseElement);
        const datePickerAlt = findIn(selectors.datePickerAlt, baseElement);
        const timeSelect = findIn(selectors.timeSelect, baseElement);
        const playButton = findIn(selectors.playButton, baseElement);
        const image = findIn(selectors.img, baseElement);
        const interval = findIn(selectors.interval, baseElement);

        let isPlaying = false;
        let webcamData;
        let intervalTime = + interval.value || 1500;
        let intervalId;
        let currentTime;
        let imagesByTime = {};

        let date = new Date;

        let url = _config.webcamPlayerArchiveUrl;

        let params = {
            date: date,
            archive: _config.webcamPlayerImgFolder
        };

        const webcamDataPromise = axios({
            method: 'GET',
            url: url,
            params: params,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json',
            }
        });

        webcamDataPromise.then((x) => x.data)
            .then(function (data) {
                webcamData = data;
                removeAttribute("disabled", playButton);

                let images = data.images;
                imagesByTime = Object.keys(images).reduce((acc, time) => ({
                    ...acc,
                    [time]: simplePreload(images[time])
                }), {});
            });

        on('change', function (evt) {
            pause();
            setAttribute('disabled', 'disabled', playButton);
            let date = datePickerAlt.value

            let url = _config.webcamPlayerArchiveUrl;

            let params = {
                date: date,
                archive: _config.webcamPlayerImgFolder
            };

            const webcamDataPromise = axios({
                method: 'GET',
                url: url,
                params: params,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json',
                }
            });

             webcamDataPromise
            .then(x => x.images)
            .then(getTimes);

            webcamDataPromise.then((x) => x.data)
                .then(function (data) {
                webcamData = data;
                removeAttribute("disabled", playButton);

                let images = data.images;
                imagesByTime = Object.keys(images).reduce((acc, time) => ({
                    ...acc,
                    [time]: simplePreload(images[time])
                }), {});

            });
        }, datePickerInput);

        if (!datePickerInput.value){
            datePickerInput.value = new Date().getTime();
            datePickerInput.trigger('change');
        }

        on('click', evt => {
            if (isPlaying) {
                pause();
            } else {
                play();
            }
        }, playButton);

        on('change', evt => {
            setTime(evt.target.value);
            pause();
        }, timeSelect);

        function play() {
            isPlaying = true;
            intervalHandler();
            addClass('is-playing',playButton);
        }

        function pause() {
            isPlaying = false;
            if (intervalId) {
                clearTimeout(intervalId);
                intervalId = null;
            }
            removeClass('is-playing',playButton);
        }

        on('change', evt => {intervalTime = evt.target.value}, interval);

        const intervalHandler = () => {
            let nextTime = getNextTime(webcamData.images, currentTime);

            if (imagesByTime[nextTime]) {
                imagesByTime[nextTime].then(() => {
                    intervalId = setTimeout(intervalHandler, intervalTime);
                    return setTime(nextTime);
                });
            } else {
                pause();
            }
        };

        function setTime(time) {
            currentTime = time;
            setTimeOption(timeSelect)(time);
            let times = getTimes(webcamData.images);
            if (times.indexOf(currentTime) + 1 >= times.length) {
                pause();
            }

            if (imagesByTime[time]) {
                imagesByTime[time]
                    .then((img) => {
                        return image.src = img.src;
                    });
            }
        }

    });
}

const getNextTime = (imgsByTime, currentTime) => {
    let times = getTimes(imgsByTime);
    let currentIndex = times.indexOf(currentTime);
    let nextIndex = currentIndex < 0
        ? 0
        : (currentIndex + 1 >= times.length
            ? currentIndex
            : currentIndex + 1);

    return times[nextIndex];
};

const getTimes = Object.keys;

const setTimeOption = select => time => select.value = time;