import {onFind} from "@elements/init-modules-in-scope";
import {removeClass, on, addClass, findAllIn, findIn} from '@elements/dom-utils';

const selectors = {
    base: '.js-scale-slider-input'
};

export function init() {
    onFind(selectors.base, function (baseElement) {
        import('nouislider').then(function (noUiSlider) {
            const slider = findIn('.js-scale-slider-input__slider', baseElement);
            const input = findIn('.js-scale-slider-input__input', baseElement);

            let sliderObj = noUiSlider.create(slider, {
                range: {
                    'min': 500,
                    '25%': 1000,
                    '50%': 1500,
                    '75%': 2000,
                    'max': 2500
                },
                start: [ input.value || 1500],
                behaviour: 'tap-drag',
                pips: {
                    mode: 'range',
                    density: 5,
                    format: {
                        to: function (value) {
                            return value / 1000 + ' sec';
                        }
                    }
                }
            });

            on('click', function (evt) {
               input.value = findIn('.noUi-handle', slider).getAttribute('aria-valuenow');
               input.dispatchEvent(new Event('change'));
            }, slider);

        });
    });
}