import {onFind} from "@elements/init-modules-in-scope";
import {removeClass, on, addClass, findAllIn, findIn} from '@elements/dom-utils';

const isMobile = matchMedia('(max-width: 767px)').matches;
export function init() {

    onFind('.js-panomax', function (baseElement) {
        let nightSwitch = findIn('.js-panomax__night-switch', baseElement);
        let iframe = findIn('.js-panomax__iframe', baseElement);
        let mobileDetailButton = findIn('.js-panomax__detail-mobile', baseElement);

        if (isMobile) {
            iframe.src = iframe.src + '?theme=noGui';
        }

        let nightUrl = isMobile ? nightSwitch.getAttribute("data-url-night-mobile") : nightSwitch.getAttribute("data-url-night");
        let dayUrl = isMobile ? nightSwitch.getAttribute("data-url-day-mobile") : nightSwitch.getAttribute("data-url-day");
        //set mobile / desktop view initially
        iframe.src = dayUrl;

        on('click', function () {
            if (nightSwitch.classList.contains('panomax-switch--day-active')) {
                removeClass('panomax-switch--day-active', nightSwitch);
                iframe.src = nightUrl;
                mobileDetailButton.href = nightSwitch.getAttribute("data-url-night");
            } else {
                addClass('panomax-switch--day-active', nightSwitch);
                iframe.src = dayUrl;
                mobileDetailButton.href = nightSwitch.getAttribute("data-url-day");
            }
        }, nightSwitch);
    });
}